import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ScrollToTop from '../Components/ScrollToTop';

const Home = () => (
  <div className="container">
    {/* Introduction */}
    <section className="pb-5">
      <h1 className="text-center text-warning">Odoo Direct Print PRO</h1>
      <p className="text-center">by VentorTech</p>

      <p className="text-center">
        Print any report or shipping labels directly to any remote or local (USB, Wi-Fi, Bluetooth)
        printer, without downloading PDF or ZPL! And weigh parcels before sending it
        to the carrier to get a price rate.
      </p>
    </section>

    {/* Buttons to apps */}
    <section className="pb-5">
      <p className="text-center">
        Odoo direct print solution includes an
        <strong> Odoo app + subscription </strong>
        to printing services.
      </p>
      <div className="text-center">
        <a
          href="https://bit.ly/OdooDP16"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-warning pl-3 pr-3 mr-md-2 ml-md-2 font-weight-bold"
        >
          Buy the app
        </a>
        <a
          href="/plans"
          className="btn btn-outline-warning pl-3 pr-3 mr-md-2 ml-md-2 font-weight-bold"
        >
          Review subscriptions
        </a>
      </div>

    </section>

    {/* Demos */}
    <section className="pb-5">
      <h4 className="text-center text-warning">WATCH QUICK DEMOS</h4>

      <div className="row">
        <div className="col-md-6 mt-4">
          <h5 className="text-center">All reports</h5>
          <div className="card">
            <div className="youtube-video">
              <a href="https://www.youtube.com/watch?v=wpw4OK1UTG8" target="_blank" rel="noreferrer">
                <img className="card-img-top" src="/images/home/quick-demo-1.jpeg" alt="quick-demo-1" />
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6 mt-4">
          <h5 className="text-center">Shipping labels</h5>
          <div className="card">
            <div className="youtube-video">
              <a href="https://www.youtube.com/watch?v=Wmv91oVADJc" target="_blank" rel="noreferrer">
                <img className="card-img-top" src="/images/home/quick-demo-2.jpeg" alt="quick-demo-2" />
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-6 offset-md-3 mt-4">
          <h5 className="text-center">WATCH FULL DEMO FROM ODOO EXPERIENCE</h5>
          <div className="card">
            <div className="youtube-video">
              <a href="https://www.youtube.com/watch?v=GlymGg5URqU" target="_blank" rel="noreferrer">
                <img className="card-img-top" src="/images/home/quick-demo-3.jpeg" alt="quick-demo-3" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center mt-4">
        This module is compatible with Odoo Community and Enterprise from v10 and higher.&nbsp;
        <b>No hardware</b>
        &nbsp;(like an IoT box or any) required.
      </p>
    </section>

    {/* Runbot */}
    <section className="p-4 bg-light mb-5">
      <div className="container">
        <h4 className="text-center pb-2 text-warning">TEST THE MODULE ON OUR RUN BOT</h4>
        <div className="text-center mb-3">
          <p><a className="btn btn-warning" href="https://odoo.ventor.tech/" target="_blank" rel="noreferrer">odoo.ventor.tech</a></p>

          <small className="text-center">
            Our Demo server is recreated each day at 12:00 a.m. (UTC). At that time,
            all of your manually entered data will be deleted.
          </small>
        </div>
      </div>
    </section>

    {/* Features */}
    <section className="pt-5 pb-5">
      <h4 className="text-center mb-3 text-warning">Features</h4>

      <div className="container">
        <div className="row pt-2 pb-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-1.jpg" alt="feature-1" />
              </div>
              <div className="col-md-9">
                <h5>Odoo Community and Enterprise</h5>
                <p>
                  Works with both Community and Enterprise editions (self-hosted or&nbsp;
                  <a className="text-warning" href="https://youtu.be/p4KE10FbYk0" target="_blank" rel="noreferrer">odoo.sh</a>
                  )
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-2.jpg" alt="feature-2" />
              </div>
              <div className="col-md-9">
                <h5>Print any Odoo report instantly</h5>
                <p>
                  The app prints different document types (ZPL, PDF) on different printers
                  without downloading files
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-2 pb-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-3.jpg" alt="feature-3" />
              </div>
              <div className="col-md-9">
                <h5>Automatic print scenarios</h5>
                <p>
                  Use built in scenarios or create your own. E.g.&nbsp;
                  <a className="text-warning" href="https://youtu.be/Wmv91oVADJc?t=37" target="_blank" rel="noreferrer">printing of Shipping Labels after Delivery Order validation</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-4.jpg" alt="feature-4" />
              </div>
              <div className="col-md-9">
                <h5>Print from barcode scanners</h5>
                <p>
                  Fully integrated with the&nbsp;
                  <a className="text-warning" href="https://youtu.be/gGfMpaet9gY?t=55" target="_blank" rel="noreferrer">Ventor</a>
                  &nbsp;mobile app and compatible with&nbsp;
                  <a className="text-warning" href="https://youtu.be/Wmv91oVADJc?t=67" target="_blank" rel="noreferrer">Odoo Barcode</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-2 pb-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-5.jpg" alt="feature-5" />
              </div>
              <div className="col-md-9">
                <h5>Smart validation</h5>
                <p>
                  Prohibits printing documents on unsupported printers, for example,
                  A4 on A6 printers or PDF on ZPL printers
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-6.jpg" alt="feature-6" />
              </div>
              <div className="col-md-9">
                <h5>Variety of supported printers</h5>
                <p>
                  Support of USB, Ethernet, WiFi, and Bluetooth printers; any format
                  and manufacturer
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-2 pb-2">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-7.jpg" alt="feature-7" />
              </div>
              <div className="col-md-9">
                <h5>Weighing parcels</h5>
                <p>
                  You can&nbsp;
                  <a className="text-warning" href="https://youtu.be/GlymGg5URqU?t=729" target="_blank" rel="noreferrer">weigh your shipping</a>
                  &nbsp;on connected scales to get correct cost from carrier
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-3 text-center">
                <img className="img img-fluid img-orange" src="/images/home/feature-8.jpg" alt="feature-8" />
              </div>
              <div className="col-md-9">
                <h5>Manage user roles</h5>
                <p>
                  Manage access rights and configure each printer individually per user or&nbsp;
                  <a className="text-warning" href="https://youtu.be/JI9xFOkm-fU?list=PLxCw4zqZMrBF9ZARpU_MpRBb6RpHkyKnB" target="_blank" rel="noreferrer">workstation</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* What's included in Price */}
    <section className="pb-5">
      <h4 className="text-center mb-2 text-warning">What&apos;s included in the price</h4>

      <ul className="list-unstyled">
        <li>
          <FontAwesomeIcon icon="check" className="mr-2 text-warning" />
          <b>Odoo Direct Print PRO app</b>
          <p />
        </li>
        <li>
          <FontAwesomeIcon icon="star" className="mr-2 text-warning" />
          <b>Regular updates, including new features</b>
          <p>
            We regularly release new functionality and fix bugs (if they occur) immediately.
            If you are familiar with our VentorTech products, you know how quickly
            we take care of everything.
          </p>
        </li>
        <li>
          <FontAwesomeIcon icon="check" className="mr-2 text-warning" />
          <b>Documentation</b>
          <p>
            Check the&nbsp;
            <a className="text-warning" href="/docs" target="_blank" rel="noreferrer">docs</a>
            &nbsp;and hints (help texts) for settings.
          </p>
        </li>
        <li>
          <FontAwesomeIcon icon="check" className="mr-2 text-warning" />
          <b>High-quality code</b>
          <p>
            Enables extending your Odoo without fear of crashes (add new features, delivery
            integrations, install third-party modules, and so on).
          </p>
        </li>
        <li>
          <FontAwesomeIcon icon="check" className="mr-2 text-warning" />
          <b>Error logs</b>
          <p>
            Alerts about failed print jobs. Human readable error log.
          </p>
        </li>
        <li>
          <FontAwesomeIcon icon="check" className="mr-2 text-warning" />
          <b>Zero support cost</b>
          <p>
            Working with Odoo since 2011, we guarantee stability of our working products and
            a lack of unnecessary errors.
          </p>
        </li>
        <li>
          <FontAwesomeIcon icon="check" className="mr-2 text-warning" />
          <b>Bugfix</b>
          <p>
            We fix critical bugs that block you.
          </p>
        </li>
        <li>
          <FontAwesomeIcon icon="minus" className="mr-2 text-warning" />
          <b>Subscription for print services is not included</b>
          <p>
            Check out&nbsp;
            <a className="text-warning" href="/plans" target="_blank" rel="noreferrer">plans</a>
            .
          </p>
        </li>
      </ul>
    </section>

    {/* Manage */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">Manage</h4>

      <div className="container">
        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-1.png" alt="manage-1" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">No additional hardware required!</h5>
            <p>
              Just install special software to your existing PCs and it works. No IoT boxes or
              other extra devices needed
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-2.jpg" alt="manage-2" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">Print any Odoo report instantly</h5>
            <p>
              All&nbsp;
              <b>standard</b>
              &nbsp;reports like Sales order, Delivery slip, Shipping label, Product barcode,
              Manufacturing order, etc. and your&nbsp;
              <b>custom</b>
              &nbsp;reports. Any format (PDF / ZPL)
            </p>

            <p>
              <a className="text-warning" href="https://youtu.be/wpw4OK1UTG8" target="_blank" rel="noreferrer">Video: Print any Odoo report &gt;</a>
            </p>

            <p>
              <small>**POS is not supported</small>
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-3.jpg" alt="manage-3" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">Set up automatic print scenarios</h5>
            <p>
              to optimize your workflows even more. E.g. print all packages after delivery order
              is validated. Activate&nbsp;
              <b>built-in scenarios</b>
              &nbsp;(15+) or create&nbsp;
              <b>your own</b>
            </p>

            <p>
              <a className="text-warning" href="https://www.youtube.com/watch?v=GlymGg5URqU&amp;t=252s" target="_blank" rel="noreferrer">Video: Print scenarios &gt;</a>
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-4.png" alt="manage-4" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">Print Shipping Labels manually or automatically</h5>
            <p>
              For Odoo Enterprise included delivery carries (FedEx, USPS, UPS, bpost, etc.).
              Also supporting all&nbsp;
              <b>custom</b>
              &nbsp;carrier integration modules for Odoo Community that are written
              according to Odoo Standards
            </p>

            <p>
              <a className="text-warning" href="https://youtu.be/Wmv91oVADJc" target="_blank" rel="noreferrer">Video: Print shipping labels &gt;</a>
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-5.jpg" alt="manage-5" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">Weigh your shipping</h5>
            <p>
              and request&nbsp;
              <b>accurate delivery cost</b>
              &nbsp;before sending a parcel to the carrier using&nbsp;
              <b>connected scales</b>
              . And print related shipping label right after that``
            </p>

            <p>
              <a className="text-warning" href="https://youtu.be/GlymGg5URqU?t=729" target="_blank" rel="noreferrer">Video: Weigh your parcels &gt;</a>
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-6.jpg" alt="manage-6" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">
              Manage access rights and assign printers to users or workstations
            </h5>
            <p />
            <p>
              <a className="text-warning" href="https://youtu.be/JI9xFOkm-fU" target="_blank" rel="noreferrer">Video: Link printer to workstation &gt;</a>
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-7.jpg" alt="manage-7" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">
              Print from Odoo web interface, from handheld (via Odoo mobile app or&nbsp;
              <a className="text-warning" href="https://youtu.be/gGfMpaet9gY" target="_blank" rel="noreferrer">Ventor</a>
              ), or automatically after triggering some action
            </h5>
            <p />
            <p>
              <a className="text-warning" href="https://youtu.be/Wmv91oVADJc" target="_blank" rel="noreferrer">Video: Print from Odoo or Ventor &gt;</a>
            </p>
          </div>
        </div>

        <div className="row pt-2 pb-4">
          <div className="col-md-8">
            <img className="img img-fluid border p-2" src="/images/home/manage-8.jpg" alt="manage-8" />
          </div>
          <div className="col-md-4 pt-3">
            <h5 className="font-weight-bold">Variety of settings and configurations</h5>
            <p>
              e.g. individual format for every report, formats supported by Printer model,
              user rules to print a certain document on a particular printer,
              delivery carrier settings, etc.
            </p>
          </div>
        </div>
      </div>
    </section>

    {/* Runbot */}
    <section className="p-4 bg-light mb-5">
      <div className="container">
        <h4 className="text-center pb-2 text-warning">TEST THE MODULE ON OUR RUN BOT</h4>
        <div className="text-center mb-3">
          <p><a className="btn btn-warning" href="https://odoo.ventor.tech/" target="_blank" rel="noreferrer">odoo.ventor.tech</a></p>

          <small className="text-center">
            Our Demo server is recreated each day at 12:00 a.m. (UTC). At that time,
            all of your manually entered data will be deleted.
          </small>
        </div>
      </div>
    </section>

    {/* Print Scenarios */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">Print scenarios</h4>

      <p>
        Besides that you can print manually (just by clicking on a button),
        you can dramatically&nbsp;
        <b>reduce your routine with automatic print scenarios</b>
        . It means that printing can be done automatically after triggering some action.
        You can define any scenario you wish based on the built in examples, or even use them.
        The module includes the most common print scenarios such as:
      </p>

      <ul>
        <li>Print Delivery Slip on Picking Status becomes Ready</li>
        <li>Print Delivery Slip for all outgoing transfers (after validation)</li>
        <li>
          Print Delivery Slip after Delivery Order Confirmation for backorder
          (created after Transfer validation)
        </li>
        <li>Print Delivery Slip only for UPS Delivery Carrier (after validation)</li>
        <li>Print Delivery Slip only for Customers from Netherlands (after validation)</li>
        <li>Print All Packages after Delivery Order is Validated</li>
        <li>Print product labels for validated transfer</li>
        <li>Print document on Sales Order (after confirmation)</li>
        <li>Print document on Purchase Order (after confirmation)</li>
        <li>
          <a className="text-warning" href="https://youtu.be/GlymGg5URqU?t=459" target="_blank" rel="noreferrer">Print Picking Document after Sales Order Validation</a>
        </li>
        <li>Print Picking Document after Purchase Order Validation</li>
        <li>
          Print single product label when processing Incoming Shipment.
          (Print single product label on Transfer (real time))
        </li>
        <li>
          <a className="text-warning" href="https://www.youtube.com/watch?v=GlymGg5URqU&amp;t=252s" target="_blank" rel="noreferrer">Print lot labels when processing Incoming Shipment</a>
          . (Print single lot label on Transfer (real time))
        </li>
        <li>Print Package on Put in Pack for Internal Pickings</li>
        <li>Print Customer Invoice after Validation</li>
      </ul>
    </section>

    {/* Compatibility */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">Compatibility</h4>

      <h5>Supported</h5>
      <ul>
        <li>Odoo Community and Enterprise</li>
        <li>
          Installed locally, externally or on&nbsp;
          <a className="text-warning" href="https://youtu.be/p4KE10FbYk0" target="_blank" rel="noreferrer">Odoo.sh</a>
        </li>
        <li>
          <a className="text-warning" href="https://www.printnode.com/en/docs/supported-printers" target="_blank" rel="noreferrer">99% of printers and scales</a>
        </li>
        <li>
          All VentorTech products (Ventor app,&nbsp;
          <a className="text-warning" href="https://apps.odoo.com/apps/modules/browse?author=VentorTech" target="_blank" rel="noreferrer">VentorTech Connectors</a>
          , etc.)
        </li>
        <li>3rd party Odoo apps developed according to Odoo standards</li>
        <li>
          Easy scalable - no functional or performance limitations based
          on number of prints (your business size)
        </li>
      </ul>

      <h5>Not supported</h5>
      <ul>
        <li>
          Fiscal printers
        </li>
        <li>
          POS
          <br />
          <small>
            Due to the design of the POS in Odoo (it is designed to work offline and
            receipts are generated on frontend and not backend),
            this module is not supporting printing for POS yet
          </small>
        </li>

      </ul>

    </section>

    {/* Print Service */}
    <section className="p-4 bg-light mb-5">
      <div className="container">
        <h4 className="text-center pb-2 text-warning">Print service</h4>
        <div className="text-center mb-3">
          <p>
            Our software uses printnode.com as a printing service.
            This is a paid service with a monthly subscription.
            We built the printnode.com subscriptions into our module so it&apos;s plug&amp;play
            software. Subscription plans are the same both on&nbsp;
            <a className="text-warning" href="/plans" target="_blank" rel="noreferrer">print.ventor.tech</a>
            {' '}
            and
            {' '}
            <a className="text-warning" href="https://www.printnode.com/en/pricing" target="_blank" rel="noreferrer">printnode.com</a>
            . We are the only&nbsp;
            <a className="text-warning" href="https://www.printnode.com/en/docs/plugins-and-libraries" target="_blank" rel="noreferrer">officially recognized software for Odoo by printnode.com</a>
          </p>
        </div>
      </div>
    </section>

    {/* Our quality standards */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">Our quality standards</h4>

      <p className="text-center">
        Provided that we had to fix many code quality issues related to a variety of connectors
        presented on the Odoo market (Ebay, Amazon, USPS, FedEx, Shopify, WooCommerce, UPS,
        Quickbooks, custom connectors, etc.) and it wasted the nerves of our customers, testers,
        and developers, we developed our&nbsp;
        <b>own quality standards</b>
        .
      </p>
      <p>
        Typical issues that clients faced using other connectors / bridges:
      </p>
      <ul>
        <li className="pb-2">
          <b>Impossibility to scale functionality together with business growth</b>
          <br />
          Most solutions are OK when you have an Odoo standard with no or very few customizations.
          But functionality breaks in a real production environment for different reasons,
          even if you make a simple change (e.g., some orders are not synchronized, prices
          for products are not synchronized, etc.);
        </li>
        <li className="pb-2">
          <b>Stability</b>
          <br />
          When even simple change had been made in Odoo (e.g., adding a barcode to a product),
          total instability occurrences increased (e.g., for some reason, orders stopped coming
          or product prices stopped syncing).
        </li>
        <li className="pb-2">
          <b>Need to pay the vendor for extending / fixes. And even this did not always help</b>
          <br />
          Our customers tried to make their connectors work at a level of at least acceptably
          &quot;stable&quot; and bought hundreds of service hours from vendors. It&apos;s reasonable
          to pay the code authors, as they should know their own apps, right? Unfortunately,
          the changes were still not working and this lowered their margin. They spent money
          with the vendors and then had to spend it again to fix it all with us, because situations
          had already become critical.
        </li>
        <li className="pb-2">
          <b>Alerts about errors</b>
          <br />
          When connectors had stopped syncing orders or prices, our clients learned about this
          only after a few days or months of sync failure, when they received customer calls
          about not delivered orders, or when they started analyzing margin and understood that
          they were selling with a resulting negative margin!
        </li>
      </ul>
      <p>
        We have been fixing these problems and similar ones in different connectors and other
        modules. Such occurrences would not present a good situation for you, because you already
        purchased the module and&nbsp;
        <b>everything is supposed to be working</b>
        . And it&apos;s not good for us, because instead of delivering features that bring benefit
        quickly, we have to&nbsp;
        <b>fix something that you think is working</b>
        .
      </p>
      <p>
        <b>Cheap solutions sound attractive</b>
        , but we have already integrated&nbsp;
        <a className="text-warning" href="https://apps.odoo.com/apps/modules/15.0/integration_shopify/" target="_blank" rel="noreferrer">Shopify</a>
        ,
        {' '}
        <a className="text-warning" href="https://apps.odoo.com/apps/modules/15.0/integration_prestashop/" target="_blank" rel="noreferrer">PrestaShop</a>
        , Magento 1, Magento 2, WooCommerce,&nbsp;
        <a className="text-warning" href="https://apps.odoo.com/apps/modules/15.0/quickbooks_sync_online/" target="_blank" rel="noreferrer">QuickBooks</a>
        , and we know -&nbsp;
        <b>these connectors cannot be cheap</b>
        ! It is not only about connectors, but about analyzing catalogs, making decisions
        about variants and product templates, figuring out what attributes should be synced
        from Odoo, and deciding on what should be edited directly on the e-commerce side only...
        and many other less-than-obvious factors.
      </p>
      <p>
        <a className="text-warning" href="https://www.youtube.com/watch?v=10m8GI0Y4Xg&amp;t=50s" target="_blank" rel="noreferrer">Odoo is like a LEGO</a>
        &nbsp;construction; it has many bricks. If looking at bricks separately, they seem
        to work fine. But everybody likes LEGOs because of the final &quot;solution&quot;.
        And combining them&nbsp;
        <b>depends on the quality of each brick</b>
        . This is why we at VentorTech deliver only high-quality products that can be
        easily integrated with other products, and
        {' '}
        <a className="text-warning" href="https://apps.odoo.com/apps/modules/browse?author=VentorTech" target="_blank" rel="noreferrer">our solutions</a>
        {' '}
        don&apos;t block you while scaling and growing.&nbsp;
        <b>
          It seems to be expensive initially but saves you a lot of investments
          and headaches in the future
        </b>
        .
      </p>
    </section>

    {/* Runbot */}
    <section className="p-4 bg-light mb-5">
      <div className="container">
        <h4 className="text-center pb-2 text-warning">TEST THE MODULE ON OUR RUN BOT</h4>
        <div className="text-center mb-3">
          <p><a className="btn btn-warning" href="https://odoo.ventor.tech/" target="_blank" rel="noreferrer">odoo.ventor.tech</a></p>

          <small className="text-center">
            Our Demo server is recreated each day at 12:00 a.m. (UTC). At that time,
            all of your manually entered data will be deleted.
          </small>
        </div>
      </div>
    </section>

    {/* FAQ */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">FAQ</h4>

      <p>
        We have created&nbsp;
        <a className="text-warning" href="/faq" target="_blank" rel="noreferrer">special page with answers to all typical questions</a>
        &nbsp;we were getting from our customers.
        There you can find answers to questions like (1) Are there any additional costs associated
        with module? (2) How to update the module when new version is released? (3)
        What is the difference from the IoT Box? and many others.
      </p>
    </section>

    {/* Installation and Configuration */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">Installation and Configuration</h4>

      <p>The installation module requires the following knowledge:</p>

      <ul>
        <li>
          How to install a module on your Odoo Instance (self-hosted or&nbsp;
          <a className="text-warning" href="https://youtu.be/p4KE10FbYk0">odoo.sh</a>
          )
        </li>
        <li>
          How to install Direct Print Client software (Linux based systems installation
          will require CUPS knowledge)
        </li>
      </ul>

      <p>
        Module installation and configuration are not included in the module&apos;s price.
        Refer to the&nbsp;
        <a className="text-warning" href="/docs">Docs</a>
        &nbsp;for installation details.
      </p>

      <p>
        If you need help, you can request paid support via&nbsp;
        <a className="text-warning" href="mailto:support@ventor.tech">support@ventor.tech</a>
        .
      </p>
    </section>

    {/* Requesting features and reporting bugs */}
    <section className="pb-5">
      <h4 className="text-center mb-4 text-warning">Requesting features and reporting bugs</h4>

      <p>
        If you have questions, need any help, or have a customizations request,
        please contact us at&nbsp;
        <a className="text-warning" href="mailto:support@ventor.tech">support@ventor.tech</a>
        .
      </p>
    </section>

    {/* *Save nature */}
    <section className="p-4 bg-light mb-5">
      <div className="container">
        <h4 className="text-center pb-2 text-warning">*Save nature</h4>
        <div className="text-center mb-3">
          <p>
            For environmental considerations, please consider printing only necessary
            paper documents.
          </p>
        </div>
      </div>
    </section>

    {/* Logo */}
    <section className="pb-5">
      {/* <div > */}
      <img className="mx-auto d-block" src="/images/home/logo.png" alt="logo" style={{ maxHeight: '300px', maxWidth: '100%' }} />
      {/* </div> */}
    </section>

    <ScrollToTop />
  </div>
);

export default Home;
